import { useEffect, useMemo, useState } from "react"
import { useObserver } from "mobx-react"
import PropTypes from "prop-types"

// MUI Components
import Autocomplete from "@mui/material/Autocomplete"
import Checkbox from "@mui/material/Checkbox"
import CircularProgress from "@mui/material/CircularProgress"
import FormControlLabel from "@mui/material/FormControlLabel"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import Tooltip from "@mui/material/Tooltip"
import Divider from "@mui/material/Divider"
import GridItem from "components/Grid/GridItem"
import DialogTitle from "@mui/material/DialogTitle"

// MUI Icons/Hooks
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import InfoIcon from "@mui/icons-material/Info"
import { makeStyles } from "@mui/styles"

// Custom Components
import { Body } from "components/Form"

// Data/Utils
import Helpers from "tools/Helpers"
import Enums from "tools/Enums.js"
import { useStore } from "contexts/rootContext"
import { ENTERTAINMENT_VERTICAL_ID } from "constants"
import { contentSections as targetElements } from "components/NarrativeAdmin/Data/contentSections"

const useStyles = makeStyles({
  chip: {
    fontSize: 12
  },
  infoIcon: {
    position: "relative",
    marginTop: "-30px",
    float: "left",
    zIndex: 2000
  },
  verticalField: {
    minWidth: "170px"
  },
  DSDivider: {
    marginTop: "24px",
    marginBottom: "9px",
    height: "5px",
    backgroundColor: "#DDEAEF"
  },
  dialogDSTitles: {
    paddingLeft: "1px",
    fontSize: "22px"
  },
  gridFieldDialog: {
    marginBottom: "15px"
  }
})

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    narrative: store.narrativeStore.narrative,
    setNarrative: store.narrativeStore.setNarrative,
    narratives: store.narrativeStore.narratives,
    organizations: store.organizationStore.organizations,
    articleTypes: store.narrativeStore.articleTypes,
    sortedLeagues: store.sportsStore.sortedLeaguesByName,
    retrieveLeagues: store.sportsStore.retrieveLeagues,
    leagueDetailsById: store.sportsStore.leagueDetailsById,
    retrieveLeagueDetails: store.sportsStore.retrieveLeagueDetails,
    feeds: store.organizationStore.feeds,
    getFeeds: store.organizationStore.getFeeds,
    loadingFeeds: store.organizationStore.loading,
    datasources: store.narrativeStore.datasources,
    getArticleTypes: store.narrativeStore.getArticleTypes,
    getContentTypes: store.narrativeStore.getContentTypes,
    contentTypes: store.narrativeStore.contentTypes,
    getDatasources: store.narrativeStore.getDatasources,
    getModelTypes: store.narrativeStore.getModelTypes,
    outputAttributes: store.narrativeStore.outputAttributes,
    getOutputAttributes: store.narrativeStore.getOutputAttributes,
    modelTypes: store.narrativeStore.modelTypes,
    queries: store.queryBuilderStore.queries,
    verticals: store.narrativeStore.verticals,
    getVerticals: store.narrativeStore.getVerticals,
    organizationProperties: store.organizationStore.organizationProperties
  }))
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const defaultStructure = [
  "entryid",
  "title",
  "content",
  "seotitle",
  "seoslug",
  "categories",
  "dscategories",
  "outputexperimentlog",
  "metadescription",
  "featuredimagejson",
  "clickdescription"
]

const triviaStructure = ["title", "entryid", "content", "introparagraph"]

const pushEventsStructure = [
  "expiredon",
  "entryid",
  "title",
  "content",
  "link",
  "dscategories",
  "messagetypeid",
  "insightscore",
  "deliverytypeid",
  "mediaurl",
  "entrytags",
  "metadata",
  "interestgroups"
]

const NarrativeBuilderStep1 = ({ renderFooter }) => {
  const classes = useStyles()
  const {
    narrative,
    setNarrative,
    organizations,
    sortedLeagues,
    retrieveLeagues,
    leagueDetailsById,
    retrieveLeagueDetails,
    narratives,
    feeds,
    loadingFeeds,
    getFeeds,
    datasources,
    contentTypes,
    modelTypes,
    queries,
    outputAttributes,
    getOutputAttributes,
    verticals,
    getVerticals,
    organizationProperties
  } = useStoreData()

  const [availableNarratives, setAvailableNarratives] = useState([])
  const [isLoadingLeagueData, setIsLoadingLeagueData] = useState(false)
  const [isEntertainmentVertical, setEntertainmentVertical] = useState(false)
  // const [selectedContentType, setSelectedContentType] = useState(0)
  const [selectedContentType, setSelectedContentType] = useState(
    !narrative.isLibrary ? 0 : Enums.NarrativeContentType.Library
  )
  const [isPreRenderType, setIsPreRenderType] = useState(false)
  // const [isPushType, setIsPushType] = useState(false)
  const [feedFields, setFeedFields] = useState([])

  const sportsData = useMemo(
    () => datasources?.filter(ds => ds.get("hasSports")),
    [datasources]
  )
  const bettingData = useMemo(
    () => datasources?.filter(ds => ds.get("hasBetting")),
    [datasources]
  )
  const imageData = useMemo(
    () => datasources?.filter(ds => ds.get("hasImages")),
    [datasources]
  )

  const leagueData = leagueDetailsById[narrative.leagueId] || {}
  const { conferences = [], divisions = [], teams = [] } = leagueData

  const conferenceList =
    conferences?.sort((a, b) => (a.name > b.name ? 1 : -1)) || []
  const divisionList =
    divisions?.sort((a, b) => (a.name > b.name ? 1 : -1)) || []
  const teamList =
    teams
      ?.filter(team => {
        if (narrative.conferenceIds?.length > 0) {
          return narrative.conferenceIds.some(id => id === team.conference_Id)
        } else if (narrative.divisionIds?.length > 0) {
          return narrative.divisionIds.some(id => id === team.division_Id)
        } else {
          return team
        }
      })
      .sort((a, b) => (a.displayName > b.displayName ? 1 : -1)) || []

  const queryOptions = useMemo(() => {
    const filteredQueries = queries?.toJS().filter(q => q.name !== "")
    return filteredQueries?.map(q => {
      const firstLetter = q.name[0].toUpperCase()
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
        ...q
      }
    })
  }, [queries])

  const handleAutocompleteChange = (name, value) => {
    if (name === "verticalId") {
      setEntertainmentVertical(value?.id === ENTERTAINMENT_VERTICAL_ID)
    }
    const newNarrative = {
      ...narrative,
      [name]: value?.id
    }
    setNarrative(newNarrative)
  }
  const handleContentTypeChange = (name, value) => {
    // console.log(`BEFORE: selectedContentType: ${selectedContentType}`)
    // console.log(`BEFORE: isPreRender: ${isPreRender}`)
    if (selectedContentType !== value?.id) {
      setSelectedContentType(value?.id)
    }
    if (
      value?.id === Enums.NarrativeContentType.Pre_Rendered_Match ||
      value?.id === Enums.NarrativeContentType.Pre_Rendered_Person
    ) {
      setIsPreRenderType(true)
    } else {
      setIsPreRenderType(false)
    }
    let newFeedFields = []
    if (value?.id === Enums.NarrativeContentType.Priority_Events_Push) {
      newFeedFields = pushEventsStructure // Push events structure
    } else if (value?.id === Enums.NarrativeContentType.Trivia) {
      newFeedFields = triviaStructure // Trivia structure
    } else {
      newFeedFields = defaultStructure
    }
    setFeedFields(newFeedFields)
    const newNarrative = {
      ...narrative,
      narrativeContentTypeId: value?.id,
      feedFieldsArray: newFeedFields // Update the feed fields array based on the content type
    }
    setNarrative(newNarrative)
    // console.log(`AFTER: selectedContentType: ${selectedContentType}`)
    // console.log(`AFTER: isPreRender: ${isPreRender}`)
  }
  const handleAutoCompleteMultiChange = (event, newValue, reason, name) => {
    if (!event || typeof event === "string" || !newValue) {
      return
    }
    let newVals = []
    newValue.forEach(itm => newVals.push(itm.id))
    const newNarrative = {
      ...narrative,
      [name]: newVals
    }
    setNarrative(newNarrative)
  }
  const handleCellChange = event => {
    const newNarrative = {
      ...narrative,
      [event.target.id]: event.target.value
    }
    setNarrative(newNarrative)
  }
  const handleCheckboxChange = event => {
    //Can be used for any checkboxes, added for "isLibrary"
    const newNarrative = {
      ...narrative,
      [event.target.id]: event.target.checked
    }
    setNarrative(newNarrative)
  }
  useEffect(() => {
    getVerticals()
    retrieveLeagues()
    getOutputAttributes()
  }, [])

  const reloadFeeds = value => {
    getFeeds(value?.id)
  }

  let availableFeeds =
    (feeds && feeds?.toJS().types.sort(Helpers.sortByName)) || []
  let availableLeagues = [{ name: "Not Applicable", id: -1 }].concat(
    sortedLeagues
  ) // Not Applicable (-1) needs to be the first in the 'list'

  const organizationPropertiesToJS = organizationProperties
    .toJS()
    .sort(Helpers.sortByName)

  const tempOrg =
    organizations?.toJS().find(o => o.id === narrative.organization?.id) || {}
  const theParent = tempOrg?.parentId
    ? tempOrg?.parentId
    : tempOrg?.id
    ? tempOrg?.id
    : ""
  let currentOrg
  //We have one ORG and possible properties.  Below we determine if our "tempOrg" is the parent Org or a Property.
  //If its an Org, make it the currentOrg.  If its a Property, make its parent the currentOrg
  if (tempOrg?.parentId) {
    currentOrg =
      organizations?.toJS().filter(itm => itm.id === tempOrg.parentId)[0] || {}
  } else {
    currentOrg = tempOrg
  }
  const orgChosenProperties = organizationPropertiesToJS.filter(
    org =>
      (org.parentId === narrative.organization?.id ||
        org.parentId === theParent) &&
      org.isActive === true
  )
  const orgProperties = [currentOrg, ...orgChosenProperties]

  useEffect(() => {
    const newavailableNarratives =
      narratives &&
      narratives.toJS().map(option => ({
        id: option.id,
        name: `${option.id} - ${option.name}`,
        friendlyName: option.name,
        isLibrary: option.isLibrary,
        isArchived: option.isArchived
      }))
    let sortedOptions = newavailableNarratives.sort(Helpers.sortByFriendlyName)
    setAvailableNarratives(sortedOptions.filter(itm => !itm.isArchived))
  }, [narratives])

  // Get new league info if a new league is selected.
  useEffect(() => {
    const fetchLeague = async leagueId => {
      try {
        await retrieveLeagueDetails(leagueId)
      } catch (err) {
        console.error(`Error fetching league ${leagueId}`, err)
      } finally {
        setIsLoadingLeagueData(false)
      }
    }

    if (narrative.leagueId !== null && narrative.leagueId !== -1) {
      setIsLoadingLeagueData(true)
      fetchLeague(narrative.leagueId)
    }
  }, [narrative.leagueId])

  useEffect(() => {
    const newNarrative = {
      ...narrative,
      teamIds: []
    }
    setNarrative(newNarrative)
  }, [narrative.conferenceIds, narrative.divisionIds])
  useEffect(() => {
    if (
      typeof narrative.buildFromOutline === "undefined" &&
      !narrative?.isLibrary
    ) {
      const newNarrative = {
        ...narrative,
        buildFromOutline: true
      }
      setNarrative(newNarrative)
    } else if (
      typeof narrative.isLibrary === "undefined" &&
      narrative?.isLibrary
    ) {
      const newNarrative = {
        ...narrative,
        isLibrary: true
      }
      setNarrative(newNarrative)
    }
  }, [narrative])

  const narrativeContentTypes = useMemo(() => {
    let narrativeContentTypes = !narrative?.isLibrary
      ? contentTypes
          ?.toJS()
          .filter(ct => ct.id !== Enums.NarrativeContentType.Library)
      : contentTypes
          ?.toJS()
          .filter(ct => ct.id === Enums.NarrativeContentType.Library)
    return narrativeContentTypes
  }, [contentTypes])

  return (
    <Body>
      <DialogTitle className={classes.dialogDSTitles}>Details</DialogTitle>
      <Grid container spacing={2} className={classes.gridFieldDialog}>
        <GridItem md={4}>
          {narrativeContentTypes && narrativeContentTypes.length > 0 && (
            <Autocomplete
              id="contentTypeId"
              options={narrativeContentTypes || []}
              value={narrativeContentTypes.find(
                c => c.id === selectedContentType
              )}
              getOptionLabel={option => option.name}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              renderInput={params => (
                <TextField {...params} label="ContentType" variant="outlined" />
              )}
              onChange={(event, value) =>
                handleContentTypeChange("contentTypeId", value)
              }
              disabled={narrative.isLibrary}
            />
          )}
        </GridItem>
      </Grid>
      <Grid container spacing={3} className={classes.gridFieldDialog}>
        <GridItem md={4}>
          <TextField
            label="NAME"
            id="name"
            variant="outlined"
            value={narrative.name}
            required
            disableClearable
            helperText="Enter a name (max 250 characters)"
            fullWidth
            onChange={e => handleCellChange(e)}
          />
        </GridItem>
        {!narrative?.isLibrary && (
          <>
            <GridItem md={4}>
              <Autocomplete
                id="organizationId"
                options={orgProperties.sort(Helpers.sortByName) || []}
                value={orgProperties.find(
                  o => o.id === narrative.organizationId
                )}
                getOptionLabel={option => option.name || ""}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    label="ORGANIZATION"
                    variant="outlined"
                    required
                  />
                )}
                onChange={(event, value) => {
                  handleAutocompleteChange("organizationId", value)
                  reloadFeeds(value)
                }}
                renderOption={(props, option) => (
                  <span {...props}>
                    {option.name}
                    {!option?.parentId && " (Parent)"}
                  </span>
                )}
                disableClearable
              />
            </GridItem>
            {!isPreRenderType && (
              <GridItem md style={{ flex: "initial" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="buildFromOutline"
                      checked={narrative?.buildFromOutline}
                      color="primary"
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Build from Outline?"
                />
              </GridItem>
            )}
          </>
        )}
      </Grid>
      <Grid
        container
        spacing={3}
        alignItems="center"
        className={classes.gridFieldDialog}
      >
        {!isEntertainmentVertical && (
          <GridItem md={3}>
            <Autocomplete
              id="leagueId"
              options={availableLeagues || []}
              value={availableLeagues?.find(l => l?.id === narrative?.leagueId)}
              getOptionLabel={option => option.name || ""}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              renderInput={params => (
                <TextField
                  {...params}
                  label="ASSOCIATED LEAGUE"
                  variant="outlined"
                />
              )}
              onChange={(event, value) =>
                handleAutocompleteChange("leagueId", value)
              }
              disableClearable
            />
          </GridItem>
        )}
        {narrative.leagueId &&
          narrative.leagueId !== -1 &&
          !isPreRenderType && (
            <>
              {isLoadingLeagueData ? (
                <CircularProgress style={{ flex: "initial" }} />
              ) : (
                <>
                  <GridItem md={3}>
                    <Tooltip
                      placement="right-end"
                      title="A user can only select either League Division or League Conference due to differing definitions by sport."
                    >
                      <InfoIcon
                        color="primary"
                        fontSize="small"
                        classes={{ root: classes.infoIcon }}
                      />
                    </Tooltip>
                    <Autocomplete
                      multiple
                      id="conferenceIds"
                      options={conferenceList}
                      value={conferenceList?.filter(i =>
                        narrative.conferenceIds?.some(j => j === i.id)
                      )}
                      onChange={(event, value, reason) =>
                        handleAutoCompleteMultiChange(
                          event,
                          value,
                          reason,
                          "conferenceIds"
                        )
                      }
                      getOptionLabel={option => option.name || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value?.id
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="LEAGUE CONFERENCE"
                          variant="outlined"
                        />
                      )}
                      limitTags={3}
                      ChipProps={{
                        classes: { root: classes.chip },
                        size: "small"
                      }}
                      disabled={
                        !narrative.leagueId || narrative.divisionIds?.length > 0
                      }
                    />
                  </GridItem>
                  <GridItem md={3}>
                    <Autocomplete
                      multiple
                      id="divisionIds"
                      options={divisionList}
                      value={divisionList?.filter(i =>
                        narrative.divisionIds?.some(j => j === i.id)
                      )}
                      onChange={(event, value, reason) =>
                        handleAutoCompleteMultiChange(
                          event,
                          value,
                          reason,
                          "divisionIds"
                        )
                      }
                      getOptionLabel={option => option.name || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value?.id
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="LEAGUE DIVISION"
                          variant="outlined"
                        />
                      )}
                      limitTags={3}
                      ChipProps={{
                        classes: { root: classes.chip },
                        size: "small"
                      }}
                      disabled={
                        !narrative.leagueId ||
                        narrative.conferenceIds?.length > 0
                      }
                    />
                  </GridItem>
                  <GridItem md={3}>
                    <Autocomplete
                      multiple
                      id="teamIds"
                      options={teamList}
                      value={teamList?.filter(i =>
                        narrative.teamIds?.some(j => j === i.id)
                      )}
                      onChange={(event, value, reason) =>
                        handleAutoCompleteMultiChange(
                          event,
                          value,
                          reason,
                          "teamIds"
                        )
                      }
                      getOptionLabel={option => option.displayName || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value?.id
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="LEAGUE TEAM"
                          variant="outlined"
                        />
                      )}
                      limitTags={3}
                      ChipProps={{
                        classes: { root: classes.chip },
                        size: "small"
                      }}
                      disabled={!narrative.leagueId}
                    />
                  </GridItem>
                </>
              )}
            </>
          )}
      </Grid>
      <Grid container spacing={4} className={classes.gridFieldDialog}>
        <GridItem md={3}>
          {verticals?.toJS().length > 0 && (
            <Autocomplete
              id="verticalId"
              options={verticals?.toJS() || []}
              value={verticals
                ?.toJS()
                .find(vertical => vertical.id === narrative?.verticalId)}
              getOptionLabel={option => option.verticalName}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              renderInput={params => (
                <TextField {...params} label="VERTICAL" variant="outlined" />
              )}
              onChange={(event, value) =>
                handleAutocompleteChange("verticalId", value)
              }
            />
          )}
        </GridItem>
        {!narrative?.isLibrary && !isPreRenderType && (
          <GridItem md={3}>
            <Autocomplete
              multiple={true}
              id="libraryNarrativeIdsArray"
              options={
                availableNarratives.filter(
                  itm => itm.isLibrary && !itm.isArchived
                ) || []
              }
              limitTags={5}
              disableCloseOnSelect
              getOptionLabel={option => option.name || ""}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              value={availableNarratives.filter(n => {
                let isMatch = false
                narrative.libraryNarrativeIdsArray?.forEach(itm => {
                  if (itm === n.id) {
                    isMatch = true
                  }
                })
                return isMatch
              })}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="LIBRARY NARRATIVE(S)"
                />
              )}
              ChipProps={{
                classes: { root: classes.chip },
                size: "small"
              }}
              onChange={(event, newValue, reason) => {
                handleAutoCompleteMultiChange(
                  event,
                  newValue,
                  reason,
                  "libraryNarrativeIdsArray"
                )
              }}
            />
          </GridItem>
        )}
        {!narrative?.isLibrary && (
          <GridItem md={3}>
            <Autocomplete
              multiple={true}
              limitTags={3}
              id="outputAttributes"
              name="outputAttributes"
              disableCloseOnSelect
              required
              options={
                outputAttributes?.toJS().sort(function (a, b) {
                  return a.attribute && a.attribute.localeCompare(b.attribute)
                }) || []
              }
              onChange={(event, newValue, reason) => {
                let outputAttributes = []
                newValue?.forEach(itm => {
                  outputAttributes.push({
                    narrativeId: 0,
                    outputAttributeId: itm.id
                  })
                })
                if (reason === "clear") {
                  outputAttributes = []
                }
                const newNarrative = {
                  ...narrative,
                  narrativeOutputAttribute: outputAttributes
                }
                setNarrative(newNarrative)
              }}
              value={outputAttributes?.toJS().filter(oAO => {
                let match = false
                narrative.narrativeOutputAttribute?.toJS().forEach(itm => {
                  if (itm.outputAttributeId === oAO.id) {
                    match = true
                  }
                })
                return match
              })}
              getOptionDisabled={option => option.isArchived === true}
              getOptionLabel={option => option.attribute || ""}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Output Attributes*"
                />
              )}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={selected}
                  />
                  {option.attribute}
                </li>
              )}
            />
          </GridItem>
        )}
      </Grid>
      {!narrative?.isLibrary && (
        <Grid container spacing={3}>
          <GridItem md={6}>
            <Autocomplete
              id="productionFeed_Id"
              name="productionFeed_Id"
              options={
                (availableFeeds &&
                  availableFeeds?.filter(f => f.isArchived === false)) ||
                []
              }
              value={availableFeeds?.find(
                f => f.id === narrative.productionFeed_Id
              )}
              getOptionLabel={option => option.name || ""}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              renderInput={params => (
                <TextField
                  {...params}
                  label="PRODUCTION FEED"
                  variant="outlined"
                />
              )}
              onChange={(event, value) =>
                handleAutocompleteChange("productionFeed_Id", value)
              }
              disabled={loadingFeeds}
            />
          </GridItem>
        </Grid>
      )}
      <Divider className={classes.DSDivider} />
      <DialogTitle className={classes.dialogDSTitles}>Data</DialogTitle>
      <Grid container spacing={3} className={classes.gridFieldDialog}>
        <GridItem md={3}>
          <Autocomplete
            id="modelTypeId"
            name="modelTypeId"
            value={modelTypes
              ?.toJS()
              .find(f => f.id === narrative?.modelTypeId)}
            options={modelTypes?.toJS().sort(Helpers.sortByName) || []}
            getOptionDisabled={option => option.isArchived === true}
            getOptionLabel={option => option.name || ""}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            renderInput={params => (
              <TextField {...params} label="MODEL TYPE" variant="outlined" />
            )}
            onChange={(event, value) =>
              handleAutocompleteChange("modelTypeId", value)
            }
          />
        </GridItem>
        <GridItem md={3}>
          {narrative.modelTypeId === Enums.NarrativeModelType.QueryBuilder && (
            <Autocomplete
              id="queryId"
              name="queryId"
              value={
                queryOptions?.find(f => f.id === narrative?.queryId) ?? null
              }
              options={queryOptions?.sort(Helpers.sortByName) || []}
              groupBy={option => option && option.firstLetter}
              getOptionLabel={option =>
                option ? `${option.id} - ${option.name}` : ""
              }
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              renderInput={params => (
                <TextField {...params} label="QUERY" variant="outlined" />
              )}
              onChange={(event, value) =>
                handleAutocompleteChange("queryId", value)
              }
            />
          )}
        </GridItem>
        <GridItem md={3}>
          {narrative.modelTypeId === Enums.NarrativeModelType.QueryBuilder && (
            <Autocomplete
              id="refreshQueryId"
              name="refreshQueryId"
              value={
                queryOptions?.find(f => f.id === narrative.refreshQueryId) ??
                null
              }
              options={queryOptions?.sort(Helpers.sortByName) || []}
              groupBy={option => option && option.firstLetter}
              getOptionDisabled={option => option.isArchived === true}
              getOptionLabel={option =>
                option ? `${option.id} - ${option.name}` : ""
              }
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              renderInput={params => (
                <TextField
                  {...params}
                  label="REFRESH QA QUERY"
                  variant="outlined"
                />
              )}
              onChange={(event, value) =>
                handleAutocompleteChange("refreshQueryId", value)
              }
            />
          )}
        </GridItem>
      </Grid>
      {!narrative?.isLibrary && !isPreRenderType && (
        <Grid container spacing={3}>
          <GridItem md={3}>
            <Autocomplete
              id="sportsDatasource1Id"
              name="sportsDatasource1Id"
              value={sportsData
                ?.toJS()
                .find(f => f.id === narrative?.sportsDatasource1Id)}
              options={sportsData?.toJS().sort(Helpers.sortByName) || []}
              getOptionLabel={option => option.name || ""}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              renderInput={params => (
                <TextField
                  {...params}
                  label="SPORTS DATASOURCE"
                  variant="outlined"
                />
              )}
              onChange={(event, value) =>
                handleAutocompleteChange("sportsDatasource1Id", value)
              }
            />
          </GridItem>
          <GridItem md={3}>
            <Autocomplete
              id="bettingDatasource1Id"
              name="bettingDatasource1Id"
              value={bettingData
                ?.toJS()
                .find(f => f.id === narrative?.bettingDatasource1Id)}
              options={bettingData?.toJS().sort(Helpers.sortByName) || []}
              getOptionLabel={option => option.name || ""}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              renderInput={params => (
                <TextField
                  {...params}
                  label="BETTING DATASOURCE"
                  variant="outlined"
                />
              )}
              onChange={(event, value) =>
                handleAutocompleteChange("bettingDatasource1Id", value)
              }
            />
          </GridItem>
          <GridItem md={3}>
            <Autocomplete
              id="imageSource1Id"
              name="imageSource1Id"
              value={imageData
                ?.toJS()
                .find(f => f.id === narrative?.imageSource1Id)}
              options={imageData?.toJS().sort(Helpers.sortByName) || []}
              getOptionLabel={option => option.name || ""}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              renderInput={params => (
                <TextField
                  {...params}
                  label="IMAGE SOURCE"
                  variant="outlined"
                />
              )}
              onChange={(event, value) =>
                handleAutocompleteChange("imageSource1Id", value)
              }
            />
          </GridItem>
        </Grid>
      )}
      {!narrative?.isLibrary && !isPreRenderType && (
        <>
          <Divider className={classes.DSDivider} />
          <DialogTitle className={classes.dialogDSTitles}>
            Structure
          </DialogTitle>
          <GridItem md={12} className={classes.MultiSelect}>
            <Autocomplete
              aria-label="NarrBuilderStep2 OutputFields DropDown"
              multiple
              id="feedFieldsArray"
              options={targetElements || {}}
              limitTags={11}
              disableCloseOnSelect
              getOptionLabel={option => option.name || ""}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              value={targetElements.filter(n => {
                let isMatch = false
                feedFields?.forEach(itm => {
                  if (itm === n.id) {
                    isMatch = true
                  }
                })
                return isMatch
              })}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Output Fields"
                />
              )}
              onChange={(event, newValue, reason) => {
                handleAutoCompleteMultiChange(
                  event,
                  newValue,
                  reason,
                  "feedFieldsArray"
                )
              }}
            />
          </GridItem>
        </>
      )}
      {renderFooter()}
    </Body>
  )
}

NarrativeBuilderStep1.propTypes = {
  renderFooter: PropTypes.func.isRequired
}

export default NarrativeBuilderStep1
