import React from "react"
import PropTypes from "prop-types"
import ReactTable from "react-table-6"
import moment, { now } from "moment"
import withStyles from "@mui/styles/withStyles"
import AccessTime from "@mui/icons-material/AccessTime"
import Language from "@mui/icons-material/Language"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Button from "components/CustomButtons/Button"
import TimeAgo from "react-timeago"
import { Cookies } from "tools/storage"
import { groupCollectionBy } from "tools/CollectionHelper"
import CardBody from "components/Card/CardBody"
import CardFooter from "components/Card/CardFooter"
import Card from "components/Card/Card"
import CardIcon from "components/Card/CardIcon"
import CardHeader from "components/Card/CardHeader"
import CustomTable from "components/Table/CustomTable"
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle"
import { Link } from "react-router-dom"
import { globalSettings } from "variables/general"
import Helpers from "tools/Helpers.js"
import {
  Chip,
  CircularProgress,
  FormControl,
  Tab,
  Tabs,
  Tooltip,
  Checkbox
} from "@mui/material"
import { TabPanel } from "components/TabPanel/TabPanel"
import { Loop } from "@mui/icons-material"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import relativeTime from "dayjs/plugin/relativeTime"
import advancedFormat from "dayjs/plugin/advancedFormat"
import {
  multiSelectFilterFunction,
  dateRangeFilterFunction
} from "components/Table/utils/TableHelpers"
import { observer, inject } from "mobx-react"
import { get } from "tools/request"

// The following was added to remove ReactTable 6 errors per this blog post
// https://spectrum.chat/react-table/general/v6-10-3-proptypes-fail-for-component-props~9ca49c7e-df58-4488-97eb-ab168570d597
/* eslint-disable react/forbid-foreign-prop-types */
// @ts-ignore
delete ReactTable.propTypes.TableComponent
// @ts-ignore
delete ReactTable.propTypes.TheadComponent
// @ts-ignore
delete ReactTable.propTypes.TbodyComponent
// @ts-ignore
delete ReactTable.propTypes.TrGroupComponent
// @ts-ignore
delete ReactTable.propTypes.TrComponent
// @ts-ignore
delete ReactTable.propTypes.ThComponent
// @ts-ignore
delete ReactTable.propTypes.TdComponent
// @ts-ignore
delete ReactTable.propTypes.TfootComponent
// @ts-ignore
delete ReactTable.propTypes.FilterComponent
// @ts-ignore
delete ReactTable.propTypes.ExpanderComponent
// @ts-ignore
delete ReactTable.propTypes.PivotValueComponent
// @ts-ignore
delete ReactTable.propTypes.AggregatedComponent
// @ts-ignore
delete ReactTable.propTypes.PivotComponent
// @ts-ignore
delete ReactTable.propTypes.PaginationComponent
// @ts-ignore
delete ReactTable.propTypes.PreviousComponent
// @ts-ignore
delete ReactTable.propTypes.NextComponent
// @ts-ignore
delete ReactTable.propTypes.LoadingComponent
// @ts-ignore
delete ReactTable.propTypes.NoDataComponent
// @ts-ignore
delete ReactTable.propTypes.ResizerComponent
// @ts-ignore
delete ReactTable.propTypes.PadRowComponent
/* eslint-enable react/forbid-foreign-prop-types */

dayjs.extend(utc).extend(relativeTime).extend(advancedFormat)

const useStore = component =>
  inject(({ store }) => ({
    accountPermissions: store.accountStore.accountPermissions,
    setShowChangeCurrentOrganization:
      store.uiStore.setShowChangeCurrentOrganization
  }))(observer(component))

class QADashboard extends React.Component {
  constructor(props) {
    super(props)
    let cookies = new Cookies()
    const qaDashboardRecentFeedEntriesPageSize = cookies.get(
      "qaDashboardRecentFeedEntriesPageSize"
    )
      ? Number(cookies.get("qaDashboardRecentFeedEntriesPageSize"))
      : 25

    this.state = {
      isFilterPanelOpen: false,
      isLoadingQAPortalMetrics: true,
      siteInfo: [],
      now: new Date(),
      allEvents: [],
      selectedEvents: [],
      relatedFeeds: [],
      modalOpen: false,
      joblogs: [],
      pausedJobs: [],
      isPausedJobsLoading: false,
      isPausedJobsLoaded: false,
      recentRenderSchedules: [],
      isRecentRenderSchedulesLoading: false,
      isRecentRenderSchedulesLoaded: false,
      selectedJobId: -1,
      expandedRows: {},
      expandedJobRows: {},
      expandedJob: null,
      modalBody: null,
      recentFeedEntriesPageSize: qaDashboardRecentFeedEntriesPageSize,
      currentView: 0,
      tableFilter: [],
      recentRenders: [],
      brokenResult: [],
      isBrokenBlocksLoading: false
    }
    this.handleClose = this.handleClose.bind(this)
    this.selectedEvent = this.selectedEvent.bind(this)
  }

  componentDidMount() {
    this.props.setShowChangeCurrentOrganization(false)
    this.retrieveRecentFeedEntriesInReview()
  }

  retrieveRecentFeedEntries() {
    this.setState({ isLoadingQAPortalMetrics: true })
    if (!this.state.recentFeedEntries) {
      get(`${globalSettings.apiBaseUrl}/api/qa/feedentries`)
        .then(Response => Response.json())
        .then(feedEntries => {
          if (feedEntries) {
            this.setState({
              recentFeedEntries: feedEntries,
              isLoadingQAPortalMetrics: false
            })
          }
        })
    } else {
      this.setState({
        isLoadingQAPortalMetrics: false
      })
    }
  }

  retrieveRecentFeedEntriesInReview() {
    this.setState({ isLoadingQAPortalMetrics: true })
    if (!this.state.recentFeedEntriesInReview) {
      get(`${globalSettings.apiBaseUrl}/api/qa/feedentriesinreview`)
        .then(Response => Response.json())
        .then(feedEntries => {
          if (feedEntries) {
            this.setState({
              recentFeedEntriesInReview: feedEntries,
              isLoadingQAPortalMetrics: false
            })
          }
        })
    }
  }

  filterEvents(events, colors, filters) {
    return (
      events &&
      events.map(event => {
        if (event && filters.indexOf(event.eventorg) > -1) {
          //Get the event color for this league
          const eventColor =
            colors && colors.find(ec => ec.eventorg === event.eventorg)
          return {
            title: event.name,
            allDay: false,
            start: moment(event.scheduledstartdatetime).toDate(),
            end: event.scheduledenddatetime
              ? moment(event.scheduledenddatetime).toDate()
              : moment(event.scheduledstartdatetime).add(2, "hours").toDate(),
            color: eventColor ? eventColor.color : "default",
            ...event
          }
        }
      })
    )
  }

  addOrRemoveFilter = filter => {
    let filters = this.state.selectedEventTypes
    const { allEvents, eventColors } = this.state
    if (filters.includes(filter)) {
      filters = filters.filter(value => value !== filter)
    } else {
      filters.push(filter)
    }
    const selectedEvents = this.filterEvents(allEvents, eventColors, filters)
    this.setState({ selectedEventTypes: filters, selectedEvents })
  }

  intFilterProps(propName, properties) {
    return {
      filterMethod: (filter, row) => {
        let currentValue = row._original[propName] || 0
        if (filter.value === "all") {
          return true
        }
        if (filter.value === ">0") {
          return currentValue > 0
        }
        if (filter.value === "=0") {
          return currentValue === 0
        }
        // NOTE: string versus integer comparison,
        // don't use === operator
        // eslint-disable-next-line eqeqeq
        return row._original[propName] == filter.value
      },
      Filter: ({ filter, onChange }) => {
        if (!properties || properties.size === 0) {
          return null
        }

        const distinctOptions = Object.keys(
          groupCollectionBy(properties, p => p[propName])
        ).sort((a, b) => {
          let comparison = 0
          if (Number(a) > Number(b)) {
            comparison = 1
          } else if (Number(a) < Number(b)) {
            comparison = -1
          }
          return comparison
        })
        // build up the <select> element <option> element JSX style
        let options = []
        distinctOptions.forEach(opt => {
          options.push(
            <option value={opt} key={opt}>
              {opt}
            </option>
          )
        })
        return (
          <select
            onChange={event => {
              onChange(event.target.value)
            }}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">All</option>
            <option value="=0">0</option>
            <option value=">0">Not 0</option>
            {/* {options} */}
          </select>
        )
      }
    }
  }
  stringFilterProps(propName, properties) {
    return {
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true
        }
        // NOTE: string versus integer comparison,
        // don't use === operator
        return (
          // eslint-disable-next-line eqeqeq
          Helpers.parseNestedObjectSelector(row._original, propName) ==
          filter.value
        )
      },
      Filter: ({ filter, onChange }) => {
        if (!properties || properties.size === 0) {
          return null
        }
        const distinctOptions = Object.keys(
          groupCollectionBy(properties, p =>
            this.getItemValueFromPath(p, propName)
          )
        ).sort((a, b) => {
          // Use toUpperCase() to ignore character casing
          const orgA = a.toUpperCase()
          const orgB = b.toUpperCase()
          let comparison = 0
          if (orgA > orgB) {
            comparison = 1
          } else if (orgA < orgB) {
            comparison = -1
          }
          return comparison
        })
        // build up the <select> element <option> element JSX style
        let options = []
        distinctOptions.forEach(opt => {
          options.push(
            <option value={opt} key={opt}>
              {opt}
            </option>
          )
        })
        return (
          <select
            onChange={event => {
              onChange(event.target.value)
            }}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">All</option>
            {options}
          </select>
        )
      }
    }
  }
  libraryNameFilterProps(properties) {
    return {
      filterMethod: (filter, row) => {
        const libraryName = row._original.libraryName

        if (filter.value === "all") {
          return true
        }

        if (filter.value === "none") {
          // Check for no library assigned (assuming null, undefined, or empty string)
          return (
            !row._original.library_ID ||
            row._original.library_ID === row._original.output_ID
          )
        }

        // String comparison, case-insensitive
        return (
          libraryName &&
          libraryName.toLowerCase() === filter.value.toLowerCase()
        )
      },
      Filter: ({ filter, onChange }) => {
        if (!properties || properties.length === 0) {
          return null
        }

        // Filter out library names where library_ID matches output_ID
        const filteredProperties = properties.filter(
          p => p.library_ID !== p.output_ID
        )

        let libraryNames = filteredProperties.map(item => item.libraryName)

        // Remove duplicates, sort, and add 'None' option for unassigned items
        libraryNames = [...new Set(libraryNames)].sort((a, b) =>
          a.localeCompare(b, undefined, { sensitivity: "base" })
        )
        libraryNames.unshift("None") // Insert 'None' at the start

        let options = libraryNames.map(name => (
          <option
            value={name.toLowerCase() === "none" ? "none" : name}
            key={name}
          >
            {name}
          </option>
        ))

        return (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">All</option>
            {options}
          </select>
        )
      }
    }
  }
  idedStringFilterProps(propName, properties) {
    return {
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true
        }
        // NOTE: string versus integer comparison,
        // don't use === operator
        return (
          // eslint-disable-next-line eqeqeq
          Helpers.parseNestedObjectSelector(row._original, propName[1]) ==
          filter.value
        )
      },
      Filter: ({ filter, onChange }) => {
        if (!properties || properties.size === 0) {
          return null
        }

        const distinctOptions = Object.keys(
          groupCollectionBy(properties, p =>
            this.getItemValueFromPath(p, propName)
          )
        ).sort((a, b) => {
          // Use toUpperCase() to ignore character casing
          const orgA = a.toUpperCase()
          const orgB = b.toUpperCase()
          let comparison = 0
          if (orgA > orgB) {
            comparison = 1
          } else if (orgA < orgB) {
            comparison = -1
          }
          return comparison
        })
        // build up the <select> element <option> element JSX style
        let options = []
        let tmpOptions = []
        distinctOptions.forEach(opt => {
          let loc = opt.indexOf(" : ")
          let tmp
          tmp = opt.substring(loc, opt.length).replace(" : ", "")
          tmpOptions.push({ val: tmp, text: opt })
        })
        tmpOptions.forEach(opt => {
          options.push(
            <option value={opt.val} key={opt.val}>
              {opt.text}
            </option>
          )
        })
        return (
          <select
            onChange={event => {
              onChange(event.target.value)
            }}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">All</option>
            {options}
          </select>
        )
      }
    }
  }

  dateFilterProps(propName) {
    return {
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true
        }
        let now = new Date()
        let futureTime = new Date()
        let pastTime = new Date()
        let columnDateTime = new Date(this.getItemValueFromPath(row, propName))
        if (filter.value === "future") {
          return columnDateTime >= now
        }
        if (filter.value === "past") {
          return columnDateTime < now
        }
        if (filter.value === "next7Days") {
          return (
            columnDateTime > now &&
            columnDateTime < futureTime.setHours(futureTime.getHours() + 7 * 24)
          )
        }
        if (filter.value === "next24hours") {
          return (
            columnDateTime > now &&
            columnDateTime < futureTime.setHours(futureTime.getHours() + 48)
          )
        }
        if (filter.value === "next1hour") {
          return (
            columnDateTime > now &&
            columnDateTime < futureTime.setHours(futureTime.getHours() + 2)
          )
        }
        if (filter.value === "within7Days") {
          return (
            columnDateTime > pastTime.setHours(pastTime.getHours() - 7 * 24) &&
            columnDateTime < futureTime.setHours(futureTime.getHours() + 7 * 24)
          )
        }
        if (filter.value === "within24hours") {
          return (
            columnDateTime > pastTime.setHours(pastTime.getHours() - 24) &&
            columnDateTime < futureTime.setHours(futureTime.getHours() + 48)
          )
        }
        if (filter.value === "within1hour") {
          return (
            columnDateTime > pastTime.setHours(pastTime.getHours() - 1) &&
            columnDateTime < futureTime.setHours(futureTime.getHours() + 2)
          )
        }
        if (filter.value === "past7Days") {
          return (
            columnDateTime > pastTime.setHours(pastTime.getHours() - 7 * 24) &&
            columnDateTime < now
          )
        }
        if (filter.value === "past24hours") {
          return (
            columnDateTime > pastTime.setHours(pastTime.getHours() - 24) &&
            columnDateTime < now
          )
        }
        if (filter.value === "past1hour") {
          return (
            columnDateTime > pastTime.setHours(pastTime.getHours() - 1) &&
            columnDateTime < now
          )
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">All</option>
          <option value="future">Future</option>
          <option value="past">Past</option>
          <option value="next7Days">Next 7 Days</option>
          <option value="next24hours">Next 24 Hours</option>
          <option value="next1hour">Next 1 Hour</option>
          <option value="within7Days">Within 7 Days</option>
          <option value="within24hours">Within 24 Hours</option>
          <option value="within1hour">Within 1 Hour</option>
          <option value="past7Days">Past 7 Days</option>
          <option value="past24hours">Past 24 Hours</option>
          <option value="past1hour">Past 1 Hour</option>
        </select>
      ),
      Cell: cell =>
        cell.value && (
          <div>
            <TimeAgo
              date={cell.value}
              title={Helpers.prettyDateTimeinPacificTimeZone(cell.value)}
            />
          </div>
        )
    }
  }
  hourFilterProps(propName) {
    return {
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true
        }
        let pastTime = new Date(filter.value)
        // eslint-disable-next-line newline-per-chained-call
        let futureTime = new moment(pastTime).add(60, "m").toDate()

        let columnDateTime = new Date(this.getItemValueFromPath(row, propName))
        return columnDateTime > pastTime && columnDateTime < futureTime
      },
      Cell: cell =>
        cell.value && (
          <div>{Helpers.shortDateTimeinPacificTimeZone(cell.value)}</div>
        )
    }
  }

  rememberPageSizeChanges = pageSize => {
    this.setState({ pageSize })
    let cookies = new Cookies()
    cookies.set("qaDashboardRecentFeedEntriesPageSize", pageSize, {
      path: "/",
      expires: Helpers.CookieExpiration.OneMonth
    })
  }

  getItemValueFromPath(item, key) {
    if (!key || key === "") {
      return null
    }
    let value = ""
    if (typeof key !== "object") {
      //Make string values an array of length 1, keep arrays as arrays to iterate through
      key = [key]
    }
    key.forEach((ke, i) => {
      if (i !== 0) {
        value += " : "
      }
      if (ke && ke.indexOf(".") !== -1) {
        // eslint-disable-line no-negated-condition
        // handle nested keys
        value += ke.split(".").reduce(function (itemObj, nestedKey) {
          return itemObj[nestedKey]
        }, item)
      } else {
        value += item[ke]
      }
    })
    return value ? [].concat(value) : null
  }
  boolFilterProps(propName) {
    return {
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true
        }
        if (filter.value === "true") {
          return row[propName] === true
        }
        if (filter.value === "false") {
          return row[propName] === false
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">All</option>
          <option value="false">Active</option>
          <option value="true">Archived</option>
        </select>
      )
    }
  }
  yesOrNoFilterProps(propName) {
    return {
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true
        }
        if (filter.value === "true") {
          return row[propName] === true
        }
        if (filter.value === "false") {
          return row[propName] === false
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">All</option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      )
    }
  }
  selectedEvent(event) {
    const historyResults =
      (this.state.recentRenders && this.state.recentRenders.length) || 0
    if (historyResults === 0) {
      return null
    }
    const historyPageSize = 1
    const eventString = (
      <CardBody style={{ width: "60vw" }}>
        <h3 style={{ textAlign: "center" }}>
          <div
            style={{
              position: "relative",
              top: "3px",
              display: "inline-block",
              backgroundColor: event.color,
              width: "25px",
              height: "25px",
              border: "1px solid #efefef",
              marginRight: "5px"
            }}
          />
          {event.eventorg} - {event.title} ({event.id})
        </h3>
        <GridContainer style={{ textAlign: "center" }}>
          <GridItem xs={3}>
            <strong>
              <u>Type</u>
            </strong>
            <br />
            {event.eventorg}&nbsp;{event.category}
          </GridItem>
          <GridItem xs={3}>
            <strong>
              <u>Start</u>
            </strong>
            <br />
            {moment(event.start).format("LL")}
            <br />
            {moment(event.start).format("h:mm A")}
            &nbsp;
            {moment()
              .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
              .zoneAbbr()}
          </GridItem>
          <GridItem xs={3}>
            <strong>
              <u>End</u>
            </strong>
            <br />
            {moment(event.end).format("LL")}
            <br />
            {moment(event.end).format("h:mm A")}
            &nbsp;
            {moment()
              .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
              .zoneAbbr()}
          </GridItem>
          <GridItem xs={3}>
            <strong>
              <u>Status</u>
            </strong>
            <br />
            {event.status}
          </GridItem>
        </GridContainer>
        <Card>
          <CardBody style={{ paddingTop: 0 }}>
            {event.type === "GenFeed" &&
              this.state.recentRenders &&
              event.status !== "Scheduled" && (
                <div
                  style={{
                    background: "#ffffff"
                  }}
                >
                  <div
                    style={{
                      padding: " 20px 0 0 0"
                    }}
                  >
                    <ReactTable
                      data={this.state.recentRenders}
                      defaultFiltered={[
                        {
                          id: "id",
                          value: event.id
                        }
                      ]}
                      noDataText={
                        this.state.isLoadingQAPortalMetrics ? (
                          <CircularProgress />
                        ) : (
                          "No job history found."
                        )
                      }
                      columns={[
                        {
                          Header: "Id",
                          accessor: "id",
                          sortable: true,
                          filterable: true,
                          width: 80
                        },
                        {
                          Header: "Possible",
                          accessor: "possiblecount",
                          sortable: true,
                          Cell: cell => (
                            <div title="Possible">
                              {cell.original.possiblecount > 0 ? (
                                <span
                                  style={{
                                    fontWeight: "bold"
                                  }}
                                >
                                  {cell.original.possiblecount}
                                </span>
                              ) : (
                                cell.original.possiblecount
                              )}
                            </div>
                          ),
                          ...this.intFilterProps(
                            "possiblecount",
                            this.state.recentRenders
                          )
                        },
                        {
                          Header: "Created",
                          accessor: "createdcount",
                          sortable: true,
                          Cell: cell => (
                            <div title="Created">
                              {cell.original.createdcount > 0 ? (
                                <span
                                  style={{
                                    color: "green",
                                    fontWeight: "bold"
                                  }}
                                >
                                  {cell.original.createdcount}
                                </span>
                              ) : (
                                cell.original.createdcount
                              )}
                            </div>
                          ),
                          ...this.intFilterProps(
                            "createdcount",
                            this.state.recentRenders
                          )
                        },
                        {
                          Header: "Updated",
                          accessor: "updatedcount",
                          sortable: true,
                          Cell: cell => (
                            <div title="Updated">
                              {cell.original.updatedcount > 0 ? (
                                <span
                                  style={{
                                    color: "green",
                                    fontWeight: "bold"
                                  }}
                                >
                                  {cell.original.updatedcount}
                                </span>
                              ) : (
                                cell.original.updatedcount
                              )}
                            </div>
                          ),
                          ...this.intFilterProps(
                            "updatedcount",
                            this.state.recentRenders
                          )
                        },
                        {
                          Header: "Skipped",
                          accessor: "skippedcount",
                          sortable: true,
                          Cell: cell => (
                            <div title="Skipped">
                              {cell.original.skippedcount > 0 ? (
                                <span
                                  style={{
                                    color: "orange",
                                    fontWeight: "bold"
                                  }}
                                >
                                  {cell.original.skippedcount}
                                </span>
                              ) : (
                                cell.original.skippedcount
                              )}
                            </div>
                          ),
                          ...this.intFilterProps(
                            "skippedcount",
                            this.state.recentRenders
                          )
                        },
                        {
                          Header: "Published",
                          accessor: "publishedcount",
                          sortable: true,
                          Cell: cell => (
                            <div title="Published">
                              {cell.original.publishedcount > 0 ? (
                                <span
                                  style={{
                                    color: "green",
                                    fontWeight: "bold"
                                  }}
                                >
                                  {cell.original.publishedcount}
                                </span>
                              ) : (
                                cell.original.publishedcount
                              )}
                            </div>
                          ),
                          ...this.intFilterProps(
                            "publishedcount",
                            this.state.recentRenders
                          )
                        },
                        {
                          Header: "Flagged",
                          accessor: "flaggedcount",
                          sortable: true,
                          Cell: cell => (
                            <div title="Flagged">
                              {cell.original.flaggedcount > 0 ? (
                                <span
                                  style={{
                                    color: "red",
                                    fontWeight: "bold"
                                  }}
                                >
                                  {cell.original.flaggedcount}
                                </span>
                              ) : (
                                cell.original.flaggedcount
                              )}
                            </div>
                          ),
                          ...this.intFilterProps(
                            "flaggedcount",
                            this.state.recentRenders
                          )
                        },
                        {
                          Header: "Killswitched",
                          accessor: "killswitchedcount",
                          sortable: true,
                          width: 120,
                          Cell: cell => (
                            <div title="Killswitched">
                              {cell.original.killswitchedcount > 0 ? (
                                <span
                                  style={{
                                    color: "red",
                                    fontWeight: "bold"
                                  }}
                                >
                                  {cell.original.killswitchedcount}
                                </span>
                              ) : (
                                cell.original.killswitchedcount
                              )}
                            </div>
                          ),
                          ...this.intFilterProps(
                            "killswitchedcount",
                            this.state.recentRenders
                          )
                        },
                        {
                          Header: "Start",
                          accessor: "startedexecutiondatetime",
                          ...this.dateFilterProps("startedexecutiondatetime"),
                          width: 120
                        },
                        {
                          Header: "End",
                          accessor: "finishedexecutiondatetime",
                          filterAll: false,
                          ...this.dateFilterProps("finishedexecutiondatetime"),
                          width: 120
                        },
                        {
                          Header: "Duration",
                          Cell: cell => {
                            let start = moment(
                              cell.original.startedexecutiondatetime
                            )
                            let end = moment(
                              cell.original.finishedexecutiondatetime || now()
                            )

                            return (
                              <strong>
                                {moment.duration(end.diff(start)).humanize()}
                              </strong>
                            )
                          },
                          width: 100,
                          filterable: false,
                          sortable: false
                        },
                        {
                          Header: "Timed Out",
                          accessor: "timedOut",
                          Cell: cell =>
                            cell.value ? <span>Yes</span> : <span>No</span>,
                          ...this.yesOrNoFilterProps(
                            "timedOut",
                            this.state.recentRenders
                          ),
                          width: 130
                        },
                        {
                          Header: "Manually Killed",
                          accessor: "killed",
                          Cell: cell =>
                            cell.value ? <span>Yes</span> : <span>No</span>,
                          ...this.yesOrNoFilterProps(
                            "killed",
                            this.state.recentRenders
                          ),
                          width: 140
                        }
                      ]}
                      filterable={true}
                      defaultPageSize={historyPageSize}
                      showPaginationTop={historyResults > historyPageSize}
                      showPaginationBottom={false}
                      className="-striped -highlight"
                      style={{
                        border: "1px solid rgba(0,0,0,0.05)",
                        background: "rgba(0,0,0,0.01)",
                        padding: "5px"
                      }}
                      defaultSorted={[
                        {
                          id: "startDateTime",
                          desc: false
                        }
                      ]}
                    >
                      {(state, makeTable) => {
                        let recordsInfoText = ""
                        const {
                          filtered,
                          pageRows,
                          pageSize,
                          sortedData,
                          page
                        } = state

                        if (sortedData && sortedData.length > 0) {
                          let isFiltered = filtered.length > 0
                          let totalRecords = sortedData.length
                          let recordsCountFrom = page * pageSize + 1
                          let recordsCountTo =
                            recordsCountFrom + pageRows.length - 1
                          if (isFiltered) {
                            recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered runs`
                          } else {
                            recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} runs`
                          }
                        } else {
                          recordsInfoText = this.state.isLoadingQAPortalMetrics
                            ? "Loading completed runs, please wait..."
                            : "This Job Has Not Run"
                        }
                        return (
                          <div className="main-grid">
                            <div className="above-table text-right">
                              <div className="col-sm-12">
                                <span className="records-info">
                                  {recordsInfoText}
                                  <Tooltip title="Reload">
                                    <Button
                                      justIcon
                                      round
                                      simple
                                      color="primary"
                                      className="info"
                                      id="reload"
                                      onClick={() => {
                                        this.retrieveRecentFeedEntries()
                                      }}
                                    >
                                      {this.state.isLoadingQAPortalMetrics ? (
                                        <CircularProgress
                                          thickness={1}
                                          size={30}
                                        />
                                      ) : (
                                        <Loop />
                                      )}
                                    </Button>
                                  </Tooltip>
                                </span>
                              </div>
                            </div>
                            {makeTable()}
                          </div>
                        )
                      }}
                    </ReactTable>
                  </div>
                </div>
              )}
            {event.type === "PublishEvent" && this.state.recentFeedEntries && (
              <div
                style={{
                  background: "#ffffff"
                }}
              >
                <div
                  style={{
                    padding: " 20px 0 0 0"
                  }}
                >
                  <ReactTable
                    data={this.state.recentFeedEntries}
                    defaultFiltered={[
                      {
                        id: "feed_id",
                        value: event.id
                      },
                      {
                        id: "modifieddatetime",
                        value: event.scheduledstartdatetime
                      }
                    ]}
                    noDataText={
                      this.state.isLoadingQAPortalMetrics ? (
                        <CircularProgress />
                      ) : (
                        "No feed entries found."
                      )
                    }
                    columns={[
                      {
                        Header: "Id",
                        accessor: "feed_id"
                      },
                      {
                        Header: "Feed",
                        accessor: "feed_id",
                        Cell: cell => (
                          <Link
                            to={{
                              pathname: `/portal/feed-entries/${cell.value}`
                            }}
                            title="View feed entries"
                          >
                            {cell.original.feed}
                          </Link>
                        ),
                        ...this.stringFilterProps(
                          "feed",
                          this.state.recentFeedEntries
                        ),
                        width: 275
                      },
                      {
                        Header: "Title",
                        accessor: "feedentry_id",
                        Cell: cell => (
                          <div>
                            <Link
                              to={{
                                pathname: `/portal/feed-entries/entry/${cell.value}`
                              }}
                              title="View feed entries"
                            >
                              {cell.original.title}
                            </Link>
                          </div>
                        ),
                        sortable: false,
                        filterable: false,
                        width: 450
                      },
                      {
                        Header: "Status",
                        accessor: "status",
                        ...this.stringFilterProps(
                          "status",
                          this.state.recentFeedEntries
                        ),
                        width: 125
                      },
                      {
                        Header: "Archived",
                        accessor: "isArchived",
                        ...this.boolFilterProps("Archived"),
                        width: 125
                      },
                      {
                        Header: "Modified",
                        accessor: "modifieddatetime",
                        ...this.hourFilterProps("modifieddatetime"),
                        width: 300
                      }
                    ]}
                    filterable={true}
                    defaultPageSize={25}
                    showPaginationBottom={true}
                    className="-striped -highlight"
                    defaultSorted={[
                      {
                        id: "modifieddatetime",
                        desc: true
                      }
                    ]}
                    getTrProps={(state, rowInfo) => {
                      if (rowInfo) {
                        let returnObject = { style: {} }
                        switch (rowInfo.original.feedEntryStatus) {
                          case 1: // Draft
                            returnObject = {
                              style: {
                                background: "#ccc"
                              }
                            }
                            break
                          case 2: // Review
                            returnObject = {
                              style: {
                                background: "#ffd900"
                              }
                            }
                            break
                        }
                        if (rowInfo.original.isArchived) {
                          returnObject.style.textDecoration = "line-through"
                        }
                        if (
                          new Date(rowInfo.original.publishedDateTime) >
                          new Date()
                        ) {
                          returnObject.style.fontStyle = "italic"
                        }
                        return returnObject
                      } else {
                        return {}
                      }
                    }}
                  >
                    {(state, makeTable) => {
                      const { filtered, pageRows, pageSize, sortedData, page } =
                        state
                      let isFiltered = false
                      let recordsCountFrom = 0
                      let recordsCountTo = 0
                      let totalRecords = 0
                      isFiltered = filtered.length > 0
                      totalRecords = sortedData.length
                      recordsCountFrom = page * pageSize + 1
                      recordsCountTo = recordsCountFrom + pageRows.length - 1
                      let niceDate = new Date(event.actualstart)
                      // eslint-disable-next-line newline-per-chained-call
                      let endDate = new moment(niceDate).add(60, "m").toDate()
                      return (
                        <div>
                          <GridContainer container>
                            <GridItem xs={12} className="text-center">
                              <FormControl>
                                <span className="records-info">
                                  {totalRecords > 0 && isFiltered && (
                                    <span>
                                      {recordsCountFrom}-{recordsCountTo} of{" "}
                                      {totalRecords} feed entries published
                                      between {niceDate.toLocaleTimeString()}{" "}
                                      and {endDate.toLocaleTimeString()}
                                    </span>
                                  )}
                                  {totalRecords > 0 && !isFiltered && (
                                    <span>
                                      {recordsCountFrom}-{recordsCountTo} of{" "}
                                      {totalRecords} total feed entries on{" "}
                                      {Helpers.prettyDateTimeinPacificTimeZone(
                                        niceDate
                                      )}
                                    </span>
                                  )}
                                  {totalRecords === 0 &&
                                    (this.state.isLoadingQAPortalMetrics
                                      ? "Loading feed entries, please wait..."
                                      : "No feed entries.")}
                                </span>
                              </FormControl>
                            </GridItem>
                          </GridContainer>
                          {makeTable()}
                        </div>
                      )
                    }}
                  </ReactTable>
                </div>
              </div>
            )}
          </CardBody>
        </Card>
      </CardBody>
    )
    this.setState({ modalOpen: true, modalBody: eventString })
  }

  handleClose() {
    this.setState({ modalOpen: false, modalBody: "" })
  }

  eventColors(event) {
    return {
      style: { backgroundColor: event.color, cursor: "pointer" }
    }
  }

  dayJsLocal = date => dayjs(date).local().format("MM-DD-YYYY HH:mm:ss")

  render() {
    if (this.state.isLoadingQAPortalMetrics) {
      return <div>loading...</div>
    }
    const { classes } = this.props

    const {
      recentFeedEntries: feedEntries,
      recentFeedEntriesInReview: feedEntriesInReview,
      currentView
      //brokenResult: brokenBlocks
    } = this.state

    const handleChange = (event, newValue) => {
      if (newValue === 1) {
        this.retrieveRecentFeedEntries()
      }
      this.setState({
        currentView: newValue
      })
    }

    const getColor = props => {
      switch (props.getValue()) {
        case "Editorial Review":
          return "secondary"
        case "Published":
          return "primary"
        default:
          return "default"
      }
    }

    const feedEntryInitialTableState = {
      sorting: [
        {
          id: "modifiedDateTime",
          desc: true
        }
      ]
    }

    const feedEntryData = feedEntries?.map(feed => ({
      ...feed,
      isArchived: feed.isArchived ? "True" : "False",
      narrativeNameAndId: `${feed.narrativeId} - ${feed.narrativeName}`,
      deliverableNameAndId: `${feed.contractdeliverableId} - ${feed.contractdeliverableName}`,
      modifiedDateTime: this.dayJsLocal(feed.modifiedDateTime)
    }))

    const feedEntryInReviewData = feedEntriesInReview?.map(feed => ({
      ...feed,
      isArchived: feed.isArchived ? "True" : "False",
      narrativeNameAndId: `${feed.narrativeId} - ${feed.narrativeName}`,
      deliverableNameAndId: `${feed.contractdeliverableId} - ${feed.contractdeliverableName}`,
      modifiedDateTime: this.dayJsLocal(feed.modifiedDateTime)
    }))

    const feedEntryColumns = [
      {
        header: "Organization",
        accessor: "organizationName",
        sortable: true,
        filterable: true,
        filterFn: multiSelectFilterFunction
      },
      {
        header: "Feed",
        accessor: "feed",
        sortable: true,
        filterable: true,
        cell: cell => (
          <Link
            to={{
              pathname: `/portal/feed-entries/${cell.row.original.feedId}`
            }}
            title="View feed entries"
          >
            {cell.getValue()}
          </Link>
        )
      },
      {
        header: "Output",
        accessor: "narrativeNameAndId",
        sortable: true,
        filterable: true,
        cell: cell => (
          <Link
            to={{
              pathname: `/portal/narrative/${cell.row.original.narrativeId}`
            }}
            title="View Output"
          >
            {cell.getValue()}
          </Link>
        )
      },
      {
        header: "League",
        accessor: "league",
        sortable: true,
        filterable: true,
        filterFn: multiSelectFilterFunction
      },
      { header: "Version", accessor: "versionNumber", sortable: true },
      {
        header: "Title",
        accessor: "title",
        sortable: true,
        cell: cell => (
          <Link
            to={{
              pathname: `/portal/feed-entries/entry/${cell.row.original.feedEntryId}`
            }}
            title="View feed entry details"
          >
            {cell.getValue() === "" ? "Title not available" : cell.getValue()}
          </Link>
        )
      },
      {
        header: "Status",
        accessor: "status",
        sortable: true,
        cell: cell => (
          <Chip
            style={{ fontSize: ".8rem" }}
            label={cell.getValue()}
            size="small"
            color={getColor(cell)}
          />
        )
      },
      {
        header: "System Archived",
        accessor: "isArchived",
        sortable: true,
        cell: cell => (
          <Checkbox
            type="checkbox"
            className="checkbox"
            checked={cell.getValue() === "True"}
            disabled
          />
        )
      },
      {
        header: "Modified",
        accessor: "modifiedDateTime",
        sortable: true,
        filterFn: dateRangeFilterFunction,
        cell: cell => (
          <span
            title={dayjs(cell.getValue()).format(
              "dddd, MMM DD, YYYY, H:mm:ss A z"
            )}
          >
            {dayjs(cell.getValue()).fromNow()}
          </span>
        )
      },
      {
        header: "Deliverable",
        accessor: "deliverableNameAndId",
        sortable: true,
        filterable: true
      }
    ]

    const tableFilters = [
      {
        filterName: "Organization",
        columnId: "organizationName",
        multiple: true,
        multiSelect: true
      },
      { filterName: "Feed", columnId: "feed" },
      {
        filterName: "Output",
        columnId: "narrativeNameAndId",
        ignoreLeadingNumbersSort: true
      },
      {
        filterName: "League",
        columnId: "league",
        multiple: true,
        multiSelect: true
      },
      { filterName: "Version", columnId: "versionNumber" },
      { filterName: "Title", columnId: "title" },
      { filterName: "Status", columnId: "status" },
      {
        filterName: "System Archived",
        columnId: "isArchived"
      },
      {
        filterName: "Modified",
        columnId: "modifiedDateTime",
        options: [
          { id: 0, value: "All" },
          { id: 1, value: "Future" },
          { id: 2, value: "Past" },
          { id: 3, value: "Next 7 Days" },
          { id: 4, value: "Next 24 Hours" },
          { id: 5, value: "Next 1 Hour" },
          { id: 6, value: "Within 7 Days" },
          { id: 7, value: "Within 24 Hours" },
          { id: 8, value: "Within 1 Hour" },
          { id: 9, value: "Past 7 Days" },
          { id: 10, value: "Past 24 Hours" },
          { id: 11, value: "Past 1 Hour" }
        ]
      },
      {
        filterName: "Deliverable",
        columnId: "deliverableNameAndId",
        ignoreLeadingNumbersSort: true
      }
    ]

    const recentFeedFilters = tableFilters.map(filter => {
      const obj = {
        ...filter
      }

      if (!filter.options) {
        obj.options = feedEntryData
          ? feedEntryData
              .map(feed => feed[filter.columnId] && feed[filter.columnId])
              .filter(
                (value, index, self) => value && self.indexOf(value) === index
              )
              .map((obj, index) => ({ id: index, value: obj }))
          : []
      }
      return obj
    })

    const reviewFeedFilters = tableFilters.map(filter => {
      const obj = {
        ...filter
      }

      if (!filter.options) {
        obj.options = feedEntryInReviewData
          ? feedEntryInReviewData
              .map(feed => feed[filter.columnId] && feed[filter.columnId])
              .filter(
                (value, index, self) => value && self.indexOf(value) === index
              )
              .map((obj, index) => ({ id: index, value: obj }))
          : []
      }
      return obj
    })

    return (
      <div>
        {this.props.accountPermissions &&
          !this.props.accountPermissions.CanSeeQADashboard && (
            <GridContainer>
              <GridItem>
                <Card>
                  <CardHeader>
                    <h3>Data Skrive Customer Success Contact information</h3>
                  </CardHeader>
                  <CardBody>
                    <div className={classes.typo}>
                      <div className={classes.note}>
                        Standard Hours of Operation
                      </div>
                      <h4>Pacific Time 8 am to 6 pm</h4>
                    </div>
                    <div className={classes.typo}>
                      <div className={classes.note}>E-mail address</div>
                      <h4>
                        <a href="mailto:cs@dataskrive.com?subject=DataSkrive Portal Support">
                          cs@dataskrive.com
                        </a>
                      </h4>
                    </div>
                    <div className={classes.typo}>
                      <div className={classes.note}>Mailing Address</div>
                      <h4>100 S. King Street, Suite 400, Seattle, WA 98104 </h4>
                    </div>
                    <div className={classes.typo}>
                      <div className={classes.note}>General Information</div>
                      <h4>
                        If you need off hours support and have not been able to
                        reach your assigned Customer Success Manager, please
                        call: <a href="tel:206-214-5206">206-214-5206</a>
                      </h4>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          )}
        {this.props.accountPermissions &&
          this.props.accountPermissions.CanSeeQADashboard && (
            <div>
              <Tabs
                value={currentView}
                onChange={handleChange}
                aria-label="QADashboard Tabs TabViewer"
              >
                <Tab label="Feed Entries In Review" />
                <Tab label="Recent Feed Entries" />
              </Tabs>
              <TabPanel value={currentView} index={0}>
                <GridContainer>
                  <GridItem xs={12}>
                    <Card chart className={classes.cardHover}>
                      <CardHeader color="info" icon>
                        <CardIcon color="info">
                          <Language />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>
                          Feed Entries In Review (72 hours)
                        </h4>
                      </CardHeader>
                      <CardBody>
                        {feedEntryInReviewData && (
                          <CustomTable
                            aria-label="QADashboard Table FeedEntries"
                            tableName="Feed Entries"
                            availableFilters={reviewFeedFilters}
                            tableData={feedEntryInReviewData}
                            tableColumns={feedEntryColumns}
                            initialTableState={feedEntryInitialTableState}
                            paginated
                            sorted
                            filtered
                            filterPanelOpen={true}
                            alternateRowColor={true}
                          />
                        )}
                      </CardBody>
                      <CardFooter chart>
                        <div className={classes.stats}>
                          <AccessTime /> updated 1 minute ago
                        </div>
                      </CardFooter>
                    </Card>
                  </GridItem>
                </GridContainer>
              </TabPanel>
              <TabPanel value={currentView} index={1}>
                <GridContainer>
                  <GridItem xs={12}>
                    <Card chart className={classes.cardHover}>
                      <CardHeader color="info" icon>
                        <CardIcon color="info">
                          <Language />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>
                          Feed Entries (24 Hours)
                        </h4>
                      </CardHeader>
                      <CardBody>
                        {feedEntryData && (
                          <CustomTable
                            aria-label="QADashboard Table FeedEntries"
                            tableName="Feed Entries"
                            availableFilters={recentFeedFilters}
                            tableData={feedEntryData}
                            tableColumns={feedEntryColumns}
                            initialTableState={feedEntryInitialTableState}
                            paginated
                            sorted
                            filtered
                            filterPanelOpen={true}
                            alternateRowColor={true}
                          />
                        )}
                      </CardBody>
                      <CardFooter chart>
                        <div className={classes.stats}>
                          <AccessTime /> updated 1 minute ago
                        </div>
                      </CardFooter>
                    </Card>
                  </GridItem>
                </GridContainer>
              </TabPanel>
            </div>
          )}
      </div>
    )
  }
}

QADashboard.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(dashboardStyle)(useStore(QADashboard))
